import makeIo from './make-io'

/**
 * Non-webp handling of images
 */
export default () => {
  const imgTags = [
    ...document.querySelectorAll('img[data-io]'),
  ].filter(({ parentNode }) => {
    return parentNode.tagName != 'PICTURE'
  })
  const images = [
    ...document.querySelectorAll('picture[data-io]'),
    ...imgTags,
  ]
  const io = makeIo(callback, { rootMargin: '76px' })
  images.forEach((img) => {
    img.removeAttribute('data-io')
    io.observe(img)
  })
}

const supportsSrcSet = 'srcset' in document.createElement('img')

/**
 * @param {Element} element
 */
const handleElement = (element, conf = {}) => {
  const {
    src = element.getAttribute('data-src'),
    srcset = element.getAttribute('data-srcset'),
  } = conf

  element.removeAttribute('data-src')
  element.removeAttribute('data-srcset')

  if (element.src) { // hot-swap
    const img = document.createElement('img')
    assign(src, srcset, img)
    img.onload = () => {
      assign(src, srcset, element)
    }
  } else assign(src, srcset, element)
}

const assign = (src, srcset, element) => {
  if (src && (!supportsSrcSet || !srcset)) {
    element.src = src
  }
  if (srcset && supportsSrcSet) {
    element.srcset = srcset
  }
}

/**
 * When the image is scrolled into view.
 * @param {!Element} target
 */
const callback = (target) => {
  const next = target.nextElementSibling
  if (next.tagName != 'NOSCRIPT') {
    console.error('Expected noscript next.')
    return
  }
  switch (target.tagName) {
  case 'PICTURE': {
    const sources = [...target.querySelectorAll('source'), target.querySelector('img')]
    sources.forEach(handleElement)
    break
  }
  case 'IMG': {
    let src = /\s+src="(.+?)"/.exec(next.textContent)
    src = src ? src[1] : undefined
    let srcset = /\s+srcset="(.+?)"/.exec(next.textContent)
    srcset = srcset ? srcset[1] : undefined

    handleElement(target, { src, srcset })
  }
  }
}