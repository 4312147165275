import '../../components/potrace/externs'
import makeIo from './make-io'

export default function WEBP() {
  const holders = [...document.querySelectorAll('img.ImageHolder')]
  const io = makeIo(callback)

  holders.forEach((holder) => {
    io.observe(holder)
  })
}

/**
 * When ImageHolder comes into view.
 * @param {Element} target
 */
const callback = (target) => {
  const noscript = /** @type {!HTMLElement} */ (target.nextElementSibling)
  if (noscript.tagName != 'NOSCRIPT') {
    return console.warn('Sibling not a noscript')
  }
  const picture = document.createElement('picture')
  target.parentElement.insertBefore(picture, target.nextSibling)
  let st = ''
  const html = noscript.textContent
    .replace(/(<picture[^>]+?)style="([^"]*?)"/, (m, g, s) => {
      st = s
      return g
    })
    .replace(/<picture(\s|>)/, `<picture style="${[st, 'display:none!important'].filter(Boolean).join(';')}"$1`)

  picture.outerHTML = html
  noscript.parentElement.removeChild(noscript)
}

/**
 * The callback for when webp is loaded.
 * @param {HTMLImageElement} img
 */
window['webploaded'] = (img) => {
  const picture = /** @type {!HTMLPictureElement} */ (img.parentElement)

  const svg = picture.previousElementSibling
  let hasPotrace
  if (!svg) {
    hasPotrace = picture.hasPotrace
  } else {
    hasPotrace = svg.getAttribute('data-potrace') !== null
    picture.hasPotrace = hasPotrace
  }

  const show = (s = 3500) => {
    const svg1 = picture.previousElementSibling
    if (svg1) svg1.parentElement.removeChild(svg1)
    picture.style.cssText = picture.style.cssText.replace(/;?\s*display:\s*none\s*!important;?$/, '')
    picture.style.animation = picture.style.webkitAnimation = `_fadeIn ${s}ms ease-out`
    return img
  }

  if (!hasPotrace) {
    return show(1500)
  }

  /** @type {!Potrace} */
  let potrace
  if (svg) {
    potrace = svg['potrace']
    picture.potrace = potrace
  } else {
    potrace = picture.potrace
  }

  if (potrace) { // svg has been initialised
    potrace.appearImg(show)
  } else if (svg) {
    svg['webp'] = {
      show,
    }
  } else {
    console.warn('svg was removed')
  }
}
