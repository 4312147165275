import WEBP from './webp'
import IMG from './img'

// refresh placeholders with srcset
window['SPLENDID_SVG_PLACEHOLDER'] = () => {
  const svgs = [...document.querySelectorAll('.ImageHolder')]
  svgs.forEach((svg) => {
    const s = /width='(\d+)'\s+height='(\d+)'/.exec(svg.src)
    if (!s) return
    const [,width, height] = s
    const aspect = width / height
    const noscript = svg.nextElementSibling
    if (noscript.tagName != 'NOSCRIPT') return
    let srcset = /<img[\s\S]*?\ssrcset="([^"]+)"/.exec(noscript.textContent)
    if (!srcset) return
    [,srcset] = srcset
    const widths = srcset.split(',').map((a) => {
      let w = /\s(\d+)w$/.exec(a)
      if (!w) return 0
      ;[, w] = w
      return w
    })
    const ss = widths.map((w) => {
      const h = w / aspect
      const sv = `<svg xmlns='http://www.w3.org/2000/svg' width='${w}' height='${h}'/>`
      const bsv = btoa(sv)
      const t = `data:image/svg+xml;base64,${bsv}`
      const src = `${t} ${w}w`
      return src
    })
    svg.srcset = ss.join(',')
    // debugger
  })
}

window['SPLENDID_SVG_PLACEHOLDER']()

window['IO'] = IMG
IMG()

window['WEBP'] = WEBP
WEBP()