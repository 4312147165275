/**
 * Creates an intersection observer.
 */
const makeIo = (callback, options = { rootMargin: '50px' }) => {
  const io = new IntersectionObserver((entries) => {
    entries.forEach(({ target, isIntersecting }) => {
      if (isIntersecting) {
        io.unobserve(target)
        callback(target)
      }
    })
  }, options)
  return io
}


export default makeIo